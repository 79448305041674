import React, { Suspense, useEffect, useState } from 'react';
import CookieConsent from 'react-cookie-consent';
// https://fluentsite.z22.web.core.windows.net/quick-start
import { Provider, teamsTheme } from '@fluentui/react-northstar';
import { HashRouter as Router } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { useTeamsFx } from './sample/lib/useTeamsFx';

import RoutesComponent from '../Routes/Routes';
import FullPageSpinner from './shared/Spinner/FullPageSpinner';
import { onMessageListener } from '../firebaseInit';
import { useAppDispatch, useAppSelector } from '../hooks/redux';
import { setIsNotificationUpdated } from '../redux/notification/notificationReducer';
import Routes2 from '../Routes/Routes2';
import Routes3 from '../Routes/Routes3';

/**
 * The main app which handles the initialization and routing
 * of the app.
 */
export default function App() {
  const { theme } = useTeamsFx();
  const [notification, setNotification] = useState({ title: '', body: '' });
  const dispatch = useAppDispatch();
  const state = useAppSelector((state) => state.loginReducers);
  const { name } = state;

  onMessageListener(setNotification)
    .then((payload: any) => {})
    .catch((err: any) => {});

  useEffect(() => {
    if (notification.body.trim() !== '') {
      toast.success(notification?.body?.split('[[')[0]);
      dispatch(setIsNotificationUpdated({}));
    }
  }, [notification]);
  const type = useSelector((state: any) => state?.loginReducers?.userType);

  return (
    <Provider
      theme={theme || teamsTheme}
      styles={{ backgroundColor: '#eeeeee' }}
    >
      <Suspense fallback={<FullPageSpinner />}>
        <Router>
          {(() => {
            if (
              type === 1 ||
              type === '' ||
              type === null ||
              type === undefined
            ) {
              return <RoutesComponent />;
            } else if (type === 2) {
              return <Routes2 />;
            } else {
              return <Routes3 />;
            }
          })()}
        </Router>
        {name ? (
          <CookieConsent
            location="bottom"
            buttonText="I agree"
            cookieName="myAwesomeCookieName2"
            style={{ background: '#2B373B', zIndex: 99999 }}
            buttonStyle={{
              color: '#fff',
              backgroundColor: '#FF833b',
              fontSize: '13px',
              position: 'relative',
              right: '480%'
            }}
            expires={150}
          >
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              Do you like the cookies? We use cokkies to ensure you get the best
              experience on our website.
            </div>
          </CookieConsent>
        ) : null}
      </Suspense>
      <ToastContainer />
    </Provider>
  );
}
