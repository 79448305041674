import { createSlice, Slice } from '@reduxjs/toolkit';

const initialState: any = {
  quickReportDesc: [],
  tagOrIntelType: '',
  activeMatrixCell: '',
  battleCard: '',
};

const slice: Slice = createSlice({
  name: 'quickReportReducer',
  initialState,
  reducers: {
    getAllQuickReportDesc: (state, { payload }) => {
      return {
        ...state,
        quickReportDesc: payload,
      };
    },
    storeTagOrIntelType: (state, { payload }) => {
      return {
        ...state,
        tagOrIntelType: payload,
      };
    },
    storeActiveMatrixCell: (state, { payload }) => {
      return {
        ...state,
        activeMatrixCell: payload,
      };
    },
    storeActiveBattleCard: (state, { payload }) => {
      return {
        ...state,
        battleCard: payload,
      };
    },
  },
});

const { actions, reducer } = slice;
export const {
  getAllQuickReportDesc,
  storeTagOrIntelType,
  storeActiveMatrixCell,
  storeActiveBattleCard,
} = actions;
export default reducer;
