import { api } from "../api";
import { createSlice, Slice } from "@reduxjs/toolkit";

const initialState: any = {
  allKiqs:[],
};

const slice: Slice = createSlice({
  name: "kiqsSlice",
  initialState,
  reducers: {

    getAllKiqsEmployee: (state, payload) => {
        return {
          ...state,
          allKiqs: [...state],
        };
      },
  },
  extraReducers(builder) {
    builder.addMatcher(
        api.endpoints.getAllKiqs.matchFulfilled,
        (state, { payload }) => {
          state.allKiqs = payload.data.kiqs;
        }
      );
  },
});

const { reducer, actions } = slice;
export const { getAllTags } = actions;
export default reducer;
