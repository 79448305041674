import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query/fetchBaseQuery";
import {
  fetchBaseQuery,
  FetchArgs,
  retry,
  BaseQueryFn,
} from "@reduxjs/toolkit/query/react";

import { RootState } from "./redux/store";
import { logoutAction } from "./redux/actions/action.creator";

export const apiRoot = process.env.REACT_APP_API_ROOT;

// Create our baseQuery instance
const baseQuery = fetchBaseQuery({
  baseUrl: apiRoot,
  prepareHeaders: (headers, { getState }) => {
    // By default, if we have a token in the store, let's use that for authenticated requests
    const token = (getState() as RootState).loginReducers.access_token;
    if (token) {
      headers.set("Authorization", token);
    }else{
      headers.set("User-Platform", 'Web');
    }
    return headers;
  },
});

// Create our baseQuery instance with Re-Authentication.
const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions);
  if (result.error?.data) {
    const responseCode: any = result.error.data;
    if (responseCode.code === 509) {
      api.dispatch(logoutAction(""));
    }
  }
  return result;
};

export const baseQueryWithRetryAndReAuth = retry(baseQueryWithReauth, {
  maxRetries: 0,
});
