import { lazy } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import MsTeamBotOAuth from '../components/modules/pages/OAuth/MsTeamsBot';
const PageNotFound = lazy(
  () => import('../components/modules/pages/PageNotFound'),
);
const SlackPage = lazy(
  () => import('../components/modules/pages/OAuth/Slack'),
);
const Login = lazy(() => import('../components/modules/Auth/Login'));
const ForgotPassword = lazy(
  () => import('../components/modules/Auth/ForgotPassword'),
);
const Auth = lazy(() => import('../components/modules/Auth'));
const Otp = lazy(() => import('../components/modules/Auth/Otp'));
const OtpSent = lazy(() => import('../components/modules/Auth/OtpSent'));

const Pages = lazy(() => import('../components/modules/pages'));
const ResetPassword = lazy(
  () => import('../components/modules/Auth/ResetPassword'),
);

//Admin Component Path

const AdminCompany = lazy(() => import('../Admin/AdminCompany'));
const AdminDashboard = lazy(() => import('../Admin/AdminDashboard'));
const AdminCompanyRequest = lazy(() => import('../Admin/AdminCompanyRequest'));

const RoutesComponent = () => {
  let routes = useRoutes([
    //Routes for authentication
    { path: '*', element: <PageNotFound /> },
  
    {
      path: '/',
      element: <Navigate replace to="/auth" />,
    },
    { path: '/company/*', element: <Navigate to="/admin/home" /> },
    { path: '/employee/*', element: <Navigate to="/admin/home" /> },
    {
      path: '*',
      element: <PageNotFound />,
    },
    {
      path: '/auth/login/ms-teams-bot/oauth',
      element: <MsTeamBotOAuth />,
    },
    {
      path: '/auth/login/slack-access',
      element: <SlackPage />,
    },
    {
      path: 'auth',
      element: <Auth />,
      children: [
        { path: '/auth', element: <Navigate replace to="login" /> },
        { path: 'login', element: <Login /> },
        {
          path: 'forgot-password',
          element: <ForgotPassword />,
        },
        { path: 'otp', element: <Otp /> },
        { path: 'otp-sent', element: <OtpSent /> },
      ],
    },
    //routes for admin
    {
      path: '/admin',
      element: <Pages />,
      children: [
        { path: '/admin', element: <Navigate replace to="home" /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'home', element: <AdminDashboard /> },
        { path: 'view-company', element: <AdminCompany /> },
        { path: 'company-request', element: <AdminCompanyRequest /> },
      ],
    },
  ]);

  return routes;
};

export default RoutesComponent;
