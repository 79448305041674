import { createSlice, Slice } from "@reduxjs/toolkit";

const initialState: any = {
  notificationUpdated:false,
};

const slice: Slice = createSlice({
  name: "notificationReducer",
  initialState,
  reducers: {
    setIsNotificationUpdated: (state) => {
        return {
          ...state,
          notificationUpdated:!state.notificationUpdated,
        };
      },
  },
});

const { reducer, actions } = slice;
export const { setIsNotificationUpdated } = actions;
export default reducer;
