/* eslint-disable prettier/prettier */
import firebase from 'firebase/compat/app';
import 'firebase/compat/messaging';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MSGSENDERID,
  appId: process.env.REACT_APP_APPID,
  measurementId: process.env.REACT_APP_MEASUREMENTID,
};
firebase.initializeApp(firebaseConfig);

let messaging = firebase.messaging();

let publicKey = process.env.REACT_APP_VAPID_KEY;

export const getToken = async () => {
  let currentToken = '';
  try {
    currentToken = await messaging.getToken({ vapidKey: publicKey });
  } catch (error) {
  }

  return currentToken;
};

export const onMessageListener = (setNotification: any) =>
  new Promise((resolve) => {
    messaging.onMessage((payload: any) => {
      if (payload?.notification?.title) {
        setNotification({
          title: payload.notification.title,
          body: payload.notification.body,
        });
      }
      resolve(payload);
    });
  });

export default firebase;
